.IBY-sections {
  .main-container {
    align-items: center;
    background: transparent linear-gradient(90deg,#e38f73,#c9705f) 0 0 no-repeat padding-box;
    display: flex;
    gap: 5rem;
    padding: 3rem 5rem;
    .highlight-info {
      width: 40%;
      color: #fff;
      margin: 2rem 0 0 auto;
      .global-common-btn{
        border-color: white;
        margin-top: 2rem;
      }
      small{
        margin-left: 20px;
      }
      h1 {
        display: inline-block;
        margin: 5rem 0 2rem;
      }
      #date-select{
        margin: 0 auto;
      }
      // #date-select-mobile{
      //   display: none;
      // }
    }
    .highlight-cover {
      height: 500px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  .about-section {
    margin: 0 auto;
    padding: 2rem 10rem;
    scroll-behavior: smooth;
    width: 85%;
    p {
      padding: 0.75rem 0;
      font-size: 1.7rem;
      text-align: justify;
    }

    .details {
      font-size: 2rem;
      font-weight: bold;
    }
    ul {
      li {
        font-size: 1.7rem;
      }
    }
    .contact-info {
      .contact {
        font-size: 2rem;
        font-weight: bold;
      }
      ul {
        list-style: none;
        li {
          padding: 0.5rem 0;
          font-size: 1.7rem;
          //  text-align: justify;
        }
      }
    }

    .online_table {
      ul {
        margin-left: 5rem;
        font-size: 1.7rem;
      }

      b {
        padding-bottom: 1rem;
      }
      table {
        width: 90%;
        border-collapse: collapse;
        tr {
          font-size: 1.7rem;
          th,
          td {
            padding: 0.5rem;
            border: 1px solid black;
            text-align: center;
          }
        }
      }
    }

    .sattvik-section {
      .nutri-page-bold{
        font-weight: 700;
      }
      .nutri-page-semi-bold{
        font-weight: 600;
      }
      ul {
        margin-left: 3rem;
        li {
          font-size: 1.7rem;
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .IBY-sections {
    .main-container {
      flex-direction: column;
      .highlight-info {
        width: 100%;
        text-align: center;
        color: #fff;
        margin-top: 2rem;
        h1 {
          margin: 0;
        }
      }
      .highlight-cover {
        height: 400px;
      }
    }
  }
}

@media (max-width: 500px) {
  .IBY-sections {
    .main-container {
      flex-direction: column;
      justify-content: center;
      .highlight-info {
        width: 100%;
        text-align: center;
        color: #fff;
        margin-top: 2rem;
        h1 {
          margin: 0;
        }
        #date-select{
          display: none;
        }
        #date-select-mobile{
          display: block;
        }
      }
      .highlight-cover {
        height: 300px;
      }
    }
    .about-section {
      padding: 2rem;
      width: 100%;
    }
  }
}
