.page-main{
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 1rem;
}

.legacy-icon{
  position: relative;
  top: 2rem;
  left: 0.5rem;
  z-index: -999;
}

.page-header{
  font-size: 64px;
}

.page-para{
  font-size: 18px;
}

.page-paragraph{
  font-size: 14px;
  margin-top: 0.5rem;
}

.page-button{
  padding: 1.2rem 3rem;
  background-color: #EA4335;
  border: none;
  border-radius: 2rem;
  margin-top: 1rem;
  color: white;
  cursor: pointer;
  font-weight: 500;
}

@media screen and (max-width: 500px) {
  .page-header{
    text-align: center;
  }
  .page-para{
    text-align: center;
  }
  .page-paragraph{
    text-align: center;
  }
}
