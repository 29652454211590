.form-content {
  form {
    label {
      display: flex;
      align-items: center;
      padding: 1rem 2rem;
      border: 1px solid #60606080;
      border-radius: 50px;
      width: 400px  ;
      //max-width: 300px;
      margin: 0 auto;
      svg {
        width: 20px !important;
        height: 20px !important;
      }

      input {
        padding-left: 20px;
        font-size: 1.8rem;
        border: none;
        width: 100%;
        text-align: center;
      }
      input:focus {
        outline: none;
      }
    }
  }
}

@media (max-width: '900px') {
  .form-content {
    form {
      label {
        padding: 1rem;
      }
    }
  }
}
  

@media (max-width:420px){
  .form-content{
    form{
      label{
       width: 300px;
      }
    }
  }
}
