.loader{
  border: 3px solid #F3F3F3;
  border-radius: 50%;
  border-top: 2px solid #CA4625;
  width: 1.5rem;
  height: 1.5rem;
  margin: 0 auto;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}