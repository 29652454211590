@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

@font-face {
  font-family: "hero";
  src: url("./Views/StaticSite/assets/font/HeroLight-Light(1).woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "bebas-neue";
  src: url("./Views/StaticSite/assets/font/bebas_neue/BebasNeue-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "futura";
  src: url("./Views/StaticSite/assets/font/FuturaStd-Medium.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

/* Anek Regular*/
@font-face {
  font-family: "Anek Regular";
  src: url('../src/Views/StaticSite/assets/font/Anek/AnekLatin-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Anek Bold";
  src: url('../src/Views/StaticSite/assets/font/Anek/AnekLatin-Bold.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Anek Condensed Thin";
  src: url('../src/Views/StaticSite/assets/font/Anek/AnekLatin_Condensed-Thin.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Anek Condensed Regular";
  src: url('../src/Views/StaticSite/assets/font/Anek/AnekLatin_Condensed-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Anek SemiExpanded ExtraThin";
  src: url('../src/Views/StaticSite/assets/font/Anek/AnekLatin_SemiExpanded-ExtraLight.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Anek ExtraLight";
  src: url('../src/Views/StaticSite/assets/font/Anek/AnekLatin-ExtraLight.ttf');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: "Futura";
  src: url('../src/Views/StaticSite/assets/font/FUTURA/tt0144m_.ttf');
  font-weight: normal;
  font-style: normal;
}


:root {
  --primary-color: #ebb38a;
  --brick-red: #ca4625;
  --brick-red-1: #bc4f31;
  --ocean-blue: #a2c8d6;
  --hero: hero;
  --bebas-neue: bebas-neue;
  --futura: futura;
  --anek: Anek
}

.wa-chat-box-poweredby {
  display: none;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-size: 62.5%;
}

body {
  font-family: 'Open Sans', sans-serif;
  overflow-x: hidden;
}

.global-padding {
  padding: 5rem 5rem 2rem 5rem;
}

.global-top-margin {
  margin-top: 3rem;
}

.global-common-btn {
  width: max-content;
  border: 3px solid black;
  padding: 1rem 2rem;
  border-radius: 5rem;
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 2rem;
  cursor: pointer;
  margin-left: 0.5rem;

  .global-common-btn-content {
    min-width: fit-content;
  }
}

.enroll-btn {
  width: max-content;
  background-color: #FFFFFF;
  box-shadow: 2px 3px 9px 0px #0000001A;
  color: #C9705F;
  padding: 1rem 2rem;
  border-radius: 5rem;
  font-size: 1.8rem;
  font-weight: bold;
  margin-top: 2.3rem;
  cursor: pointer;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  margin-left: 0.5rem;

  .img {
    height: 1.3rem;
  }

  &:hover {
    background-color: #FFE3DE;
    box-shadow: 0px 5px 4px 0px #71382E;
  }

  .enroll-btn-content {
    min-width: fit-content;
  }

}

.global-loader {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
}

h1 {
  margin: 0;
  font-size: 4rem;
  font-weight: bold;
}

h2 {
  margin: 0;
  font-weight: 400;
  font-size: 3rem;
}

h4 {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 500;
}

h5 {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 700;
}

h6 {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 500;
}

p {
  margin: 0;
  font-size: 2rem;
  font-weight: 400;
}

a {
  text-decoration: none;
  color: inherit;
}

.slick-dots {
  padding-bottom: 5rem !important;
  bottom: -40px !important;
  position: relative !important;
}

.slick-dots li {
  margin: 0 2rem !important;
}

.slick-dots li.slick-active button::before {
  opacity: 1 !important;
  color: var(--brick-red) !important;
}

.slick-dots li button::before {
  font-size: 1.5rem !important;
}

.filler-logo {
  padding: 0 4rem;
  z-index: -1;
}

.filler-logo svg {
  width: 25%;
}

.user-container {
  cursor: pointer;
}

.form-field {
  margin: 2rem auto;
}


@media (max-width: 1920px) {
  html {
    font-size: 80%;
  }

  .global-common-btn {
    padding: 1.5rem 2rem;
    font-size: 1.5rem;
    margin-top: 5rem;
  }

  .global-top-margin {
    margin-top: 1rem;
  }

  .slick-dots li {
    margin: 0 3rem !important;
  }
}

@media (max-width: 1800px) {
  html {
    font-size: 62.5%;
  }

  .global-common-btn {
    padding: 1rem 2rem;
    font-size: 1.5rem;
  }

  .global-top-margin {
    margin-top: 0;
  }
}

@media (max-width: 900px) {
  html {
    font-size: 55%;
  }

  .slick-dots li {
    margin: 0 1rem !important;
  }

  .global-padding {
    padding: 1.5rem;
  }
}

@media (max-width: 420px) {
  h1 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 2rem;
  }

  p {
    font-size: 1.5rem;
  }

  .global-padding {
    padding: 1.5rem;
  }
}

/* src/CookieBanner.css */

.cookie-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #333;
  color: #fff;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  gap: 12px;
}
@media screen and (max-width: 599px){
  .cookie-banner {
    flex-direction: column;
  }
}
.cookie-banner p {
  margin: 0;
  font-size: 14px;
}

.cookie-button {
  background-color: green;
  font-weight: 800;
  border: none;
  padding: 8px 16px;
  color: #fff;
  cursor: pointer;
  border-radius: 4px;
}

.cancel-cookie {
  background-color: #ec971f;
  margin-right: 10px;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}