.course-card {
  display: inline-block;
  width: 380px;
  position: relative;
  flex: 1;
  


  img {
    object-fit: cover;
    width: 100%;
    height: 135px; //100%;
    border-radius: 12px 12px 0 0;
  }

  .course-card-image {
    position: relative;
    margin-bottom: -3.5px;
    height: 135px; //250px;
    cursor: pointer;

    .stars {
      position: absolute;
      display: flex;
      justify-content: space-between;
      width: 30%;
      bottom: 1rem;
      left: 2rem;
    }
  }

  .course-card-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    color: #fff;
    padding: 1rem;
    // min-height: fit-content;
    border-radius: 0 0 12px 12px;
    height: 313px;
    overflow-y: hidden;
    h4 {
      // font-weight: 700;
      height: 43px;
      width: 100%;
      font-size: 1.3rem;
      font-weight: 700;
    }

    h3 {
      // padding-bottom: 5rem;
      // font-size: 1.25rem;
      // font-weight: 500;
      // margin: 1rem 0;
      // height: 0px;
      font-size: 1rem;
      font-weight: 400 // display: -webkit-box;
        // -webkit-line-clamp: 2;
        // -webkit-box-orient: vertical;
        // overflow: hidden;
        // text-overflow: ellipsis;
    }

    a {
      color: #fff;
      text-decoration: none;
    }

    .det-label {
      font-size: 14px;
      font-weight: 400;
      padding-left: 6px;
      position: relative;
      top: -4px
    }

    .det-val {
      font-size: 14px;
      font-weight: 600;
      position: relative;
      top: -4px
    }

    .course-card-dropdown {
      border-color: blue;
      cursor: pointer;
      margin-top: 0;
      position: relative;
    }

    .global-common-btn {
      border-color: white;
      cursor: pointer;
      margin-top: 0;
      position: relative;

    }

    .Button-class {
      display: flex;
      justify-content: space-between;
      position: relative;
      bottom: 0rem;
      top: 0rem;

      // padding-right: 5rem;
      // flex-wrap: wrap;
      .global-common-btn {
        font-size: 1.5rem;
        padding: 0.5rem 1.25rem !important;
        border-width: 0.1rem;
        margin-top: 1rem !important;
        margin-left: 0;
      }

      .enroll-nw {
        background-color: #FFFFFF;
        color: #CA4625;
      }
    }
  }
}

@media (max-width: 620px) {
  .course-card {
    width: 100%;

    .course-card-content {
      height: auto;
      .Button-class {
        left: 0rem;
        gap: 1rem;
      }
    }
    .enroll-nw, .global-common-btn {
      font-size: 16px !important;
      padding: 6 20px !important;
    }
  }
  h4 {
    font-size: 15px !important;
    height: 70px !important;
  }


}

.course-card .course-card-content h3 {
  padding-bottom: 5rem;
  font-size: 1.25rem;
  font-weight: 500;
  margin: 3rem 0;
  height: 45px;
}