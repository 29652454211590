.Dates-dropdown {
  position: relative;
  // &::after {
  //   content: '\25B6';
  //   color: white;
  //   padding-left: 15px;
  //   display: inline-block;
  //   font-size: 1.25rem;
  //   vertical-align: top;
  //   margin-top: 3px;
  //   position: absolute;
  //   left: 90px;
  // }
  .Select-dropdown {
    padding: 0.8rem 0.8rem;
    //appearance: none;
    font-size: 1.5rem;
    font-weight: 900;
    border: none;
    color: white;
    border-radius: 15%/50%;
    background: transparent;
    &:focus-visible {
      outline: white;
    }
  }

}
.open {
  &::after{
    transition: 0.2s ease;
    transform: rotate(90deg);
  }
    }

@media (max-width: 620px) {
  .course-card {
    width: 100%;
  }
  .Select-dropdown {
    border-color: white;
    cursor: pointer;
    position: relative;
    display: flex;
    // left: rem;
  }
}
