.blog-card {
  border-radius: 25px !important;
  width: 95%;
  display: grid;
  //grid-template-rows: 60% 40%;
  margin: 0 auto;
  cursor: pointer;
  .blog-card-image {
    img {
      border-radius: 25px 25px 0px 0px;
      box-sizing: border-box;
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }
  .blog-card-text {
    background-color: var(--brick-red);
    color: #fff;
    border-radius: 0px 0px 25px 25px;
    padding: 2rem;

    .title {
      font-weight: 700;
      font-size: 1.8rem;
      height: 5rem;
      padding-bottom: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .blogs_meta {
      padding-top: 1rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}

@media (max-width: 1400px) {
  .blog-card .blog-card-text p {
    font-size: 1.75rem;
  }
}

@media (max-width: 1300px) {
  .blog-card {
    .blog-card-image {
      img {
        object-fit: cover;
        height: 100%;
      }
    }
    .blog-card-text {
      padding-top: 10px;
      padding-left: 20px;
      h2 {
        font-size: 2.5rem;
      }
      p {
        font-size: 1.5rem;
      }
    }
  }
}
@media (max-width: 900px) {
  .blog-card {
    margin: 0 auto;
  }
}
@media (max-width: 786px) {
  .blog-card {
    margin: 0 auto;
  }
}
